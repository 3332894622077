import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { AccountNavigationLinkType } from '../AccountNavigationLink';

export type FragmentAccountNavigationLinksType = Document & {
	_type: 'FragmentAccountNavigationLinks';
	setupMembership: AccountNavigationLinkType;
	manageBilling: AccountNavigationLinkType;
	accountSettings: AccountNavigationLinkType;
	referAFriend: AccountNavigationLinkType;
	faqs: AccountNavigationLinkType;
	legal: AccountNavigationLinkType;
	trustpilot: AccountNavigationLinkType;
	updateBillingInformation: AccountNavigationLinkType;
	invoiceHistory: AccountNavigationLinkType;
	updatePaymentMethod: AccountNavigationLinkType;
	cancelMembership: AccountNavigationLinkType;
	terms: AccountNavigationLinkType;
	privacyPolicy: AccountNavigationLinkType;
	appStoreReview: {
		playStoreUrl: string;
		appStoreUrl: string;
		appStoreTitle: string;
		playStoreTitle: string;
	};
};

export default defineType({
	name: 'FragmentAccountNavigationLinks',
	type: 'document',
	fields: [
		{
			name: 'setupMembership',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'manageBilling',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'accountSettings',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'referAFriend',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'faqs',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'legal',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'trustpilot',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'appStoreReview',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'playStoreTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'playStoreUrl',
					type: 'string',
					validation: (r) => r.required().uri({ scheme: ['https'] }),
				},
				{
					name: 'appStoreTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'appStoreUrl',
					type: 'string',
					validation: (r) => r.required().uri({ scheme: ['https'] }),
				},
			],
		},
		{
			name: 'updateBillingInformation',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'invoiceHistory',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'updatePaymentMethod',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'cancelMembership',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'terms',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
		{
			name: 'privacyPolicy',
			type: 'AccountNavigationLink',
			validation: (r) => r.required(),
		},
	],
});
