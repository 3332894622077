import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { PollType } from '../Poll';

export type FragmentStreaksType = {
	_type: 'FragmentStreaks';
	notifications: Array<{
		title: string;
		content: string;
	}>;
	streaksCalendar: {
		title: InlineContentType;
		subtitle?: InlineContentType;
		daysPracticedContent: InlineContentType;
	};
	streakGained: {
		title: InlineContentType;
		subtitle?: InlineContentType;
		messages: Array<{ message: InlineContentType }>;
		nextCtaText: string;
	};
	streaksCommitment: {
		title: InlineContentType;
		content: BlockContentType;
		poll: PollType;
		nextCtaText: string;
		confirmCtaText: string;
	};
};

export default defineType({
	name: 'FragmentStreaks',
	type: 'document',
	fields: [
		{
			name: 'notifications',
			description:
				'Notifications to show in the streaks, we will randomly select one of these to show to the user',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'title',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'content',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'streaksCalendar',
			description: 'Content for the streaks calendar screen',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.length(1).required(),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
					validation: (r) => r.length(1),
				},
				{
					name: 'daysPracticedContent',
					type: 'InlineContent',
					validation: (r) => r.length(1).required(),
				},
			],
		},
		{
			name: 'streakGained',
			type: 'object',
			description: 'Content for the streak gained screen',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
					validation: (r) => r.length(1),
				},
				{
					name: 'messages',
					type: 'array',
					validation: (r) => r.required(),
					of: [
						{
							type: 'object',
							validation: (r) => r.required(),
							fields: [
								{
									name: 'message',
									type: 'InlineContent',
									validation: (r) => r.required(),
								},
							],
							preview: previewBlockContent('message'),
						},
					],
				},
				{
					name: 'nextCtaText',
					type: 'string',
					initialValue: 'Next',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'streaksCommitment',
			type: 'object',
			description: 'Content for the streaks commitment screen',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'content',
					type: 'BlockContent',
				},
				{
					name: 'poll',
					type: 'reference',
					to: [{ type: 'Poll' }],
					validation: (r) => r.required(),
				},
				{
					name: 'nextCtaText',
					type: 'string',
					initialValue: 'Next',
					validation: (r) => r.required(),
				},
				{
					name: 'confirmCtaText',
					type: 'string',
					initialValue: 'Commit',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
