import { HiOutlineMusicNote } from '@react-icons/all-files/hi/HiOutlineMusicNote';
import { defineType, Slug } from '@sanity/types';

import { Document } from './_types';
import { slugify } from './_utils';
import { BlockContentType } from './block/_types';
import { SoundEpisodeType } from './SoundEpisode';

export type SoundSeriesType = Document & {
	_type: 'SoundSeries';
	name: string;
	slug: Slug;
	description: BlockContentType;
	episodes: Array<SoundEpisodeType>;
};

export default defineType({
	name: 'SoundSeries',
	type: 'document',
	icon: HiOutlineMusicNote,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'slug',
			type: 'slug',
			validation: (r) => r.required(),
			options: {
				source: 'name',
				slugify,
			},
		},
		{
			name: 'description',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'episodes',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'SoundEpisode',
					type: 'reference',
					to: [{ type: 'SoundEpisode' }],
				},
			],
		},
	],
	preview: {
		select: {
			name: 'name',
			episodes: 'episodes',
		},
		prepare({
			name: title,
			episodes,
		}: {
			name: string;
			episodes: SoundEpisodeType[];
		}) {
			return {
				title,
				subtitle:
					episodes.length === 1 ? '1 episode' : `${episodes.length} episodes`,
			};
		},
	},
});
