import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentDailyTasksType = Document & {
	_type: 'FragmentDailyTasks';
	notificationPromptTitle: string;
	notificationPromptBody: InlineContentType;
	notificationPromptCtaText: string;
};

export default defineType({
	name: 'FragmentDailyTasks',
	type: 'document',
	fields: [
		{
			name: 'notificationPromptTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'notificationPromptBody',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'notificationPromptCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'completeDailyTasksText',
		},
	},
});
