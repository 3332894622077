import { defineType } from '@sanity/types';

import { Document, Image } from '../_types';
import { previewBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';

export type FragmentCheckoutSubscriptionPlansType = Document & {
	_type: 'FragmentCheckoutSubscriptionPlans';
	paymentChargeContent: InlineContentType;
	discountContent: InlineContentType;
	ctaText: string;
	discountInputLabel: string;
	discountInputPlaceholder: string;
	applyDiscountCtaText: string;
	v2: {
		title: string;
		paidTrialTitle: string;
		trustpilot: string;
		listItems: {
			firstItem: string;
			secondItem: string;
			thirdItem: string;
			thirdItemTest: string;
		};
		plans: {
			key: string;
			name: string;
			billingPeriod: string;
			billingDescription: string;
		}[];
		yearlySavingHeader: InlineContentType;
		paymentChargeContent: InlineContentType;
		lifetimeIOSPaymentChargeContent: InlineContentType;
	};
	beforeYouGo: {
		image: Image;
		title: InlineContentWrapperType;
		content: InlineContentWrapperType;
		ctaText: string;
		skipCta: string;
	};
};

export default defineType({
	name: 'FragmentCheckoutSubscriptionPlans',
	type: 'document',
	fields: [
		{
			name: 'paymentChargeContent',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'discountContent',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'discountInputLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'discountInputPlaceholder',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'applyDiscountCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'v2',
			type: 'object',
			validation: (r) => r.required(),
			options: { collapsible: true },
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'paidTrialTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'trustpilot',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'listItems',
					type: 'object',
					validation: (r) => r.required(),
					fields: [
						{
							name: 'firstItem',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'secondItem',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'thirdItem',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'thirdItemTest',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
				{
					name: 'plans',
					type: 'array',
					validation: (r) => r.required().min(1),
					of: [
						{
							name: 'item',
							type: 'object',
							fields: [
								{
									name: 'key',
									type: 'string',
									validation: (r) => r.required(),
								},
								{
									name: 'name',
									type: 'string',
									validation: (r) => r.required(),
								},
								{
									name: 'billingPeriod',
									type: 'string',
									validation: (r) => r.required(),
								},
								{
									name: 'billingDescription',
									type: 'string',
									validation: (r) => r.required(),
								},
							],
						},
					],
				},
				{
					// not in use right now - copy currently hardcoded
					name: 'yearlySavingHeader',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'paymentChargeContent',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'lifetimeIOSPaymentChargeContent',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'beforeYouGo',
			type: 'object',
			description:
				'Content to show when the user presses exit checkout on subscription plans screen',
			fields: [
				{
					name: 'image',
					type: 'image',
					validation: (r) => r.required(),
				},
				{
					name: 'title',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'skipCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
	preview: previewBlockContent(),
});
