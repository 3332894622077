import { HiOutlineMicrophone } from '@react-icons/all-files/hi/HiOutlineMicrophone';
import { defineType } from '@sanity/types';

import { Document, File } from './_types';

export type AudioType = Document & {
	_type: 'Audio';
	name: string;
	file: File;
};

export default defineType({
	name: 'Audio',
	type: 'document',
	icon: HiOutlineMicrophone,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'file',
			type: 'file',
			validation: (r) => r.required(),
			options: {
				accept: 'audio/*',
			},
		},
	],
	preview: {
		select: {
			title: 'name',
		},
	},
});
