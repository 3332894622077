import { ProgramActivityFilterExactActivityType } from './ProgramActivityFilterExactActivity';
import { ProgramActivityFilterMaxAgeType } from './ProgramActivityFilterMaxAge';
import { ProgramActivityFilterSingleMetadataType } from './ProgramActivityFilterSingleMetadata';

export type ProgramActivityFilterType =
	| ProgramActivityFilterExactActivityType
	| ProgramActivityFilterMaxAgeType
	| ProgramActivityFilterSingleMetadataType;

export const programActivityFilterTypes = [
	'ProgramActivityFilterExactActivity',
	'ProgramActivityFilterMaxAge',
	'ProgramActivityFilterSingleMetadata',
];

export enum AvailabilityType {
	ActiveMember = 'active_member',
	User = 'user',
}

export enum ProgramActivityStateType {
	Live = 'live',
	Archived = 'archived',
}
