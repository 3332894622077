import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent, validateSnakeCase } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { CancelationDiscountOfferType } from '../CancelationDiscountOffer';
import { CancelationQuestionType } from '../CancelationQuestion';

export type CancelationReasonType = {
	reasonKey: string;
	reasonTitle: string;
	subQuestion?: CancelationQuestionType;
	investorFriendlyQuestion?: CancelationQuestionType;
	discountOffer?: CancelationDiscountOfferType;
};

export type FragmentCancelationFlowType = Document & {
	_type: 'FragmentCancelationFlow';
	title: string;
	subtitle?: InlineContentType;
	reasons: CancelationReasonType[];
	cancelationSuccess: {
		headline?: InlineContentType;
		title: string;
		content: BlockContentType;
		continueCtaText: string;
		dismissCtaText: string;
	};
	postCancelationQuestions?: {
		headline?: InlineContentType;
		title: string;
		subtitle?: InlineContentType;
	}[];
	postCancelationSuccess: {
		title: string;
		content: BlockContentType;
		dismissCtaText: string;
	};
	noDiscountCancelationConfirmation: {
		title: string;
		subtitle?: InlineContentType;
		content: BlockContentType;
		confirmCancelationText: string;
		dismissCtaText: string;
	};
	discountAppliedSuccess: {
		title: string;
		content: BlockContentType;
		doneCtaText: string;
	};
};

export default defineType({
	name: 'FragmentCancelationFlow',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
		},
		{
			name: 'reasons',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'reasonKey',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{
							name: 'reasonTitle',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'subQuestion',
							type: 'CancelationQuestion',
							options: {
								collapsible: true,
							},
						},
						{
							name: 'investorFriendlyQuestion',
							type: 'CancelationQuestion',
							options: {
								collapsible: true,
							},
						},
						{
							name: 'discountOffer',
							type: 'CancelationDiscountOffer',
							options: {
								collapsible: true,
							},
						},
					],
					preview: {
						select: {
							title: 'reasonTitle',
						},
					},
				},
			],
			validation: (r) => r.required().min(1),
		},
		{
			name: 'cancelationSuccess',
			type: 'object',
			fields: [
				{
					name: 'headline',
					type: 'InlineContent',
				},
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
				{
					name: 'continueCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'dismissCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'postCancelationQuestions',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{ name: 'headline', type: 'InlineContent' },
						{ name: 'title', type: 'string', validation: (r) => r.required() },
						{ name: 'subtitle', type: 'InlineContent' },
					],
				},
			],
		},
		{
			name: 'postCancelationSuccess',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
				{
					name: 'dismissCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'noDiscountCancelationConfirmation',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
				{
					name: 'confirmCancelationText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'dismissCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'discountAppliedSuccess',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
				{
					name: 'doneCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
	preview: previewBlockContent(),
});
