import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { IconType } from '../Icon';

export type DayComplete = {
	type: 'content_with_flair' | 'content_without_flair';
	title: string;
	content: BlockContentType;
};

type Notice = {
	icon: IconType;
	title: InlineContentType;
	content: InlineContentType;
};

export type FragmentHomeTasksType = Document & {
	_type: 'FragmentHomeTasks';
	firstDayComplete: DayComplete;
	subsequentDayComplete: DayComplete;
	noTasksCompleted: Notice;
	someTasksCompleted: Notice;
};

const dayCompleteFields = [
	defineField({
		name: 'type',
		type: 'string',
		validation: (r) => r.required(),
		options: {
			list: [
				{ title: 'Content with flair', value: 'content_with_flair' },
				{ title: 'Content without flair', value: 'content_without_flair' },
			],
		},
	}),
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'content',
		type: 'BlockContent',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FragmentHomeTasks',
	type: 'document',
	fields: [
		{
			name: 'firstDayComplete',
			type: 'object',
			validation: (r) => r.required(),
			fields: dayCompleteFields,
		},
		{
			name: 'subsequentDayComplete',
			type: 'object',
			validation: (r) => r.required(),
			fields: dayCompleteFields,
		},
		{
			name: 'noTasksCompleted',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'icon',
					type: 'Icon',
					validation: (r) => r.required(),
				},
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'someTasksCompleted',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'icon',
					type: 'Icon',
					validation: (r) => r.required(),
				},
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
