import {
	ConditionalPropertyCallbackContext,
	defineField,
	defineType,
	ValidationContext,
} from '@sanity/types';

import { File } from '../_types';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBaseStepType } from './baseTypes';

export type FlowExercisePelvicFloorSqueezeStepType = FlowBaseStepType & {
	_type: 'FlowExercisePelvicFloorSqueezeStep';
	squeezeTest: SqueezeSection & {
		durationPopupConfirmation: PopupConfirmation;
		repsPopupConfirmation: PopupConfirmation;
	};
	squeezeWorkout: SqueezeSection & {
		popupConfirmation: PopupConfirmation;
	};
};

const pageTypeOptions = {
	list: [
		{ title: 'Block content', value: 'block_content' },
		{ title: 'Squeeze duration exercise page', value: 'squeeze_duration' },
		{ title: 'Squeeze reps exercise page', value: 'squeeze_rep' },
	],
};

const tooltipFields = [
	defineField({
		name: 'dynamicContent',
		type: 'InlineContent',
		description:
			'Dynamic content shown in the tooltip (e.g. duration/reps count)',
	}),
	defineField({
		name: 'staticContent',
		type: 'string',
		description: 'Static content shown in the tooltip',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'ctaText',
		type: 'string',
		description: 'Text for the CTA button in the tooltip',
	}),
];

const popupFields = [
	defineField({
		name: 'content',
		type: 'InlineContent',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'primaryCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'secondaryCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

const pageFields = [
	defineField({
		name: 'squeezeStep',
		type: 'object',
		fields: [
			{
				name: 'pageType',
				type: 'string',
				validation: (r) => r.required(),
				options: pageTypeOptions,
			},
			{
				name: 'title',
				type: 'InlineContent',
				hidden: (doc) => hideBlockContentFields(doc),
				validation: (r) => r.length(1),
			},
			{
				name: 'content',
				type: 'BlockContent',
				hidden: (doc) => hideBlockContentFields(doc),
				validation: (r) =>
					r.custom((value, context) => {
						const parent = context.parent as SqueezePage;
						if (!value && parent.pageType === 'block_content') {
							return 'Required';
						}
						return true;
					}),
			},
			{
				name: 'lottieFile',
				type: 'file',
				options: {
					accept: 'application/json',
				},
				hidden: (doc) => hideBlockContentFields(doc),
			},
			{
				name: 'durationSeconds',
				type: 'number',
				description: 'Duration in seconds for the squeeze or block content',
				hidden: ({ parent }) => {
					return !parent?.pageType || parent?.pageType === 'squeeze_rep';
				},
				validation: (r) =>
					r
						.custom<number>((value, context) => {
							const parent = context.parent as SqueezePage;
							if (!value && parent.pageType === 'squeeze_duration') {
								return 'Required';
							}
							return true;
						})
						.integer()
						.positive(),
			},
			{
				name: 'startCtaText',
				type: 'string',
				hidden: (doc) => hideCta(doc),
				validation: (r) => r.custom(validateCta),
			},
			{
				name: 'resetCtaText',
				type: 'string',
				hidden: (doc) => hideCta(doc),
				validation: (r) => r.custom(validateCta),
			},
			{
				name: 'buttonText',
				type: 'string',
				description: 'Text for the button that starts the squeeze',
				hidden: ({ parent }) => {
					return !parent?.pageType || parent?.pageType === 'block_content';
				},
				validation: (r) =>
					r.custom((value, context) => {
						const parent = context.parent as SqueezePage;
						if (
							!value &&
							parent.pageType &&
							parent.pageType !== 'block_content'
						) {
							return 'Required';
						}
						return true;
					}),
			},
			{
				name: 'tooltipStart',
				type: 'object',
				fields: tooltipFields,
				description: 'Tooltip content shown at the start of the squeeze',
				hidden: (doc) => hideTooltipField(doc),
				validation: (r) => r.custom(validateTooltipField),
			},
			{
				name: 'tooltipEnd',
				type: 'object',
				fields: tooltipFields,
				description: 'Tooltip content shown at the end of the squeeze',
				hidden: (doc) => hideTooltipField(doc),
				validation: (r) => r.custom(validateTooltipField),
			},
		],
		preview: {
			select: {
				content: 'content',
				pageType: 'pageType',
			},
			prepare: ({ content, pageType }) => {
				return {
					title:
						pageType === 'block_content'
							? `${content[0].children[0].text}`
							: pageType === 'squeeze_duration'
								? 'Squeeze duration'
								: 'Squeeze rep',
					subtitle: pageType,
				};
			},
		},
	}),
];

const helperTipsFields = [
	defineField({
		name: 'title',
		type: 'InlineContent',
		validation: (r) => r.required().length(1),
	}),
	defineField({
		name: 'content',
		type: 'BlockContent',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FlowExercisePelvicFloorSqueezeStep',
	type: 'document',
	fields: [
		{
			name: 'squeezeTest',
			type: 'object',
			validation: (r) => r.required(),
			options: { collapsible: true },
			fields: [
				{
					name: 'pages',
					type: 'array',
					validation: (r) => r.required().min(1),
					of: pageFields,
				},
				{
					name: 'helperTips',
					type: 'object',
					fields: helperTipsFields,
				},
				{
					name: 'durationPopupConfirmation',
					type: 'object',
					description:
						'Popup confirmation shown after the squeeze baseline duration',
					validation: (r) => r.required(),
					fields: popupFields,
				},
				{
					name: 'repsPopupConfirmation',
					type: 'object',
					description:
						'Popup confirmation shown after the squeeze baseline reps',
					validation: (r) => r.required(),
					fields: popupFields,
				},
			],
		},
		{
			name: 'squeezeWorkout',
			type: 'object',
			validation: (r) => r.required(),
			options: { collapsible: true },
			fields: [
				{
					name: 'pages',
					type: 'array',
					validation: (r) => r.required().min(1),
					of: pageFields,
				},
				{
					name: 'helperTips',
					type: 'object',
					validation: (r) => r.required(),
					fields: helperTipsFields,
				},
				{
					name: 'popupConfirmation',
					type: 'object',
					description: 'Popup confirmation shown after the squeeze',
					validation: (r) => r.required(),
					fields: popupFields,
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Complete',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
});

const validateCta = (value: string, context: ValidationContext) => {
	const parent = context.parent as SqueezePage;
	if (!value && parent.pageType === 'block_content' && parent.durationSeconds) {
		return 'Required';
	}
	return true;
};

const hideBlockContentFields = ({
	parent,
}: ConditionalPropertyCallbackContext) => {
	return parent?.pageType !== 'block_content';
};

const hideTooltipField = ({ parent }: ConditionalPropertyCallbackContext) => {
	return !parent?.pageType || parent?.pageType === 'block_content';
};

const hideCta = ({ parent }: ConditionalPropertyCallbackContext) => {
	const durationSeconds = parent?.durationSeconds;
	return !durationSeconds || parent?.pageType !== 'block_content';
};

const validateTooltipField = <T>(value: T, context: ValidationContext) => {
	const parent = context.parent as SqueezePage;
	if (parent.pageType === 'block_content') return true;
	return value ? true : 'Required';
};

type SqueezeSection = {
	pages: SqueezePage[];
	helperTips?: HelperTips;
};

type SqueezePage =
	| BlockContentStepContent
	| SqueezeDurationStepContent
	| SqueezeRepStepContent;

type BlockContentStepContent = {
	pageType: 'block_content';
	title: InlineContentType;
	content: BlockContentType;
	lottieFile?: File;
	durationSeconds?: number;
	startCtaText?: string;
	resetCtaText?: string;
};

type SqueezeDurationStepContent = BaseSqueezeStepContent & {
	pageType: 'squeeze_duration';
	buttonText: string;
	durationSeconds: number;
};

type SqueezeRepStepContent = BaseSqueezeStepContent & {
	buttonText: string;
	pageType: 'squeeze_rep';
};

type BaseSqueezeStepContent = {
	tooltipStart: TooltipContent;
	tooltipEnd: TooltipContent;
};

type TooltipContent = {
	dynamicContent?: InlineContentType;
	staticContent: string;
	ctaText?: string;
};

type HelperTips = {
	title: InlineContentType;
	content: BlockContentType;
};

type PopupConfirmation = {
	content: InlineContentType;
	primaryCtaText: string;
	secondaryCtaText: string;
};
