import { Document } from '../_types';
import { ExpressionBooleanType } from '../expressions/_types';
import { Flow24hTrialStepType } from './Flow24hTrialStep';
import { FlowAudioPlayerStepType } from './FlowAudioPlayerStep';
import { FlowCalendlyBookingStepType } from './FlowCalendlyBookingStep';
import { FlowCommunityTopicStepType } from './FlowCommunityTopicStep';
import { FlowContentStepType } from './FlowContentStep';
import { FlowEfficacyMeasurementStepType } from './FlowEfficacyMeasurementStep';
import { FlowExercisePelvicFloorSqueezeStepType } from './FlowExercisePelvicFloorSqueezeStep';
import { FlowExerciseRESupportStepType } from './FlowExerciseRESupportStep';
import { FlowExerciseTimedBoxBreathAnimationStepType } from './FlowExerciseTimedBoxBreathAnimationStep';
import { FlowExerciseTimedContentStepType } from './FlowExerciseTimedContentStep';
import { FlowExerciseTimedImageStepType } from './FlowExerciseTimedImageStep';
import { FlowNotificationsPreferenceStepType } from './FlowNotificationsPreferenceStep';
import { FlowPelvicExerciseStepType } from './FlowPelvicExerciseStep';
import { FlowPollQuestionAnswerStepType } from './FlowPollQuestionAnswerStep';
import { FlowPollStepType } from './FlowPollStep';
import { FlowSWScoreOnboardingResultStatStepType } from './FlowSWScoreOnboardingResultStatStep';
import { FlowSWScoreOnboardingResultStepType } from './FlowSWScoreOnboardingResultStep';
import { FlowSWScoreOnboardingStepType } from './FlowSWScoreOnboardingStep';
import { FlowWrapUpStepType } from './FlowWrapUpStep';

export const flowStepTypes = [
	'Flow24hTrialStep',
	'FlowAudioPlayerStep',
	'FlowCalendlyBookingStep',
	'FlowCommunityTopicStep',
	'FlowContentStep',
	'FlowEfficacyMeasurementStep',
	'FlowPelvicExerciseStep',
	'FlowExercisePelvicFloorSqueezeStep',
	'FlowExerciseRESupportStep',
	'FlowExerciseTimedBoxBreathAnimationStep',
	'FlowExerciseTimedContentStep',
	'FlowExerciseTimedImageStep',
	'FlowNotificationsPreferenceStep',
	'FlowPollQuestionAnswerStep',
	'FlowPollStep',
	'FlowSWScoreOnboardingResultStatStep',
	'FlowSWScoreOnboardingResultStep',
	'FlowSWScoreOnboardingStep',
	'FlowWrapUpStep',
];

export type FlowStepType =
	| Flow24hTrialStepType
	| FlowAudioPlayerStepType
	| FlowCalendlyBookingStepType
	| FlowCommunityTopicStepType
	| FlowContentStepType
	| FlowEfficacyMeasurementStepType
	| FlowExercisePelvicFloorSqueezeStepType
	| FlowExerciseRESupportStepType
	| FlowExerciseTimedBoxBreathAnimationStepType
	| FlowExerciseTimedContentStepType
	| FlowExerciseTimedImageStepType
	| FlowPelvicExerciseStepType
	| FlowNotificationsPreferenceStepType
	| FlowPollQuestionAnswerStepType
	| FlowPollStepType
	| FlowSWScoreOnboardingResultStatStepType
	| FlowSWScoreOnboardingResultStepType
	| FlowSWScoreOnboardingStepType
	| FlowWrapUpStepType;

export const flowItemTypes = [...flowStepTypes, 'FlowGroup'];
export type FlowItemType = FlowStepType | FlowGroupType;

export type FlowGroupType = Document & {
	_type: 'FlowGroup';
	cases?: FlowGroupCaseType[];
	defaultItems?: FlowItemType[];
};

type FlowGroupCaseType = {
	condition: ExpressionBooleanType[];
	items?: FlowItemType[];
};
