import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBasePageType } from './baseTypes';

export type Flow24hTrialStepType = FlowBasePageType & {
	_type: 'Flow24hTrialStep';
	subtitleHighlight?: InlineContentType;
	content?: BlockContentType;
};

export default defineType({
	name: 'Flow24hTrialStep',
	type: 'document',
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'subtitleHighlight',
			type: 'InlineContent',
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
