import { BiPoll } from '@react-icons/all-files/bi/BiPoll';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField } from './_utils';
import { PollOptionType } from './PollOption';

export type PollType = Document & {
	_type: 'Poll';
	id: string;
	name: string;
	preventResubmission: boolean;
	maxChoices: number;
	randomizeOptions: boolean;
	randomizationFixedTopCount: number;
	randomizationFixedBottomCount: number;
	options: Array<PollOptionType>;
};

export default defineType({
	name: 'Poll',
	type: 'document',
	icon: BiPoll,
	fields: [
		ksuidIdField('poll', true),
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'dataClassification',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'special_category',
			readOnly: (c) =>
				(c.document as PollType | undefined)?.std_published ?? false,
			description:
				'Please look through the "Pseudonyms" page on Notion before changing this field',
			options: {
				list: [
					{ title: 'Special category', value: 'special_category' },
					{ title: 'Standard personal data', value: 'standard_personal' },
				],
			},
		},
		{
			name: 'preventResubmission',
			type: 'boolean',
			validation: (r) => r.required(),
			initialValue: false,
		},
		{
			name: 'maxChoices',
			type: 'number',
			validation: (r) =>
				r
					.required()
					.integer()
					.positive()
					.custom<number>((n) => {
						if (n !== 1) {
							return 'Multiple choice polls are not supported yet';
						}
						return true;
					}),
			initialValue: 1,
		},
		{
			name: 'randomizeOptions',
			type: 'boolean',
			validation: (r) => r.required(),
			initialValue: false,
		},
		{
			name: 'randomizationFixedTopCount',
			type: 'number',
			description:
				'Number of options to keep at the top of the list when randomizing',
			validation: (r) => r.required().integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) => !parent.randomizeOptions,
		},
		{
			name: 'randomizationFixedBottomCount',
			type: 'number',
			description:
				'Number of options to keep at the bottom of the list when randomizing',
			validation: (r) => r.required().integer().positive(),
			initialValue: 0,
			hidden: ({ parent }: { parent: PollType }) => !parent.randomizeOptions,
		},
		{
			name: 'options',
			type: 'array',
			of: [{ type: 'PollOption' }],
			validation: (r) => r.required(),
		},
	],
	validation: (r) =>
		r.custom<Required<PollType>>((value) => {
			const fixedCount =
				(value?.randomizationFixedTopCount ?? 0) +
				(value?.randomizationFixedBottomCount ?? 0);
			const randomizedCount = (value?.options?.length ?? 0) - fixedCount;
			switch (true) {
				case Boolean(value?.randomizeOptions && randomizedCount >= 2):
				case Boolean(!value?.randomizeOptions && fixedCount === 0):
					return true;
				default:
					return 'Invalid randomization configuration';
			}
		}),
	preview: {
		select: {
			title: 'name',
			subtitle: 'id',
		},
	},
});
