import { HiOutlineChartSquareBar } from '@react-icons/all-files/hi/HiOutlineChartSquareBar';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { PollType } from '../Poll';
import { FlowBasePageType } from './baseTypes';

export type FlowPollStepType = FlowBasePageType & {
	_type: 'FlowPollStep';
	content?: BlockContentType;
	poll: PollType;
	pollHideResults: boolean;
	pollSelectionRequired: boolean;
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowPollStep',
	type: 'document',
	icon: HiOutlineChartSquareBar,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
		},
		{
			name: 'poll',
			type: 'reference',
			to: [{ type: 'Poll' }],
			validation: (r) => r.required(),
		},
		{
			name: 'pollHideResults',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'pollSelectionRequired',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
